//variabili sovrascrivibili da css
:root{
  --primary : #006a7c;
}


$primary: var(--primary);
$light-background:rgba($primary, 0.04);
$medium-background:rgba($primary, 0.30);
$neutral:#f7fafc;
$secondary: #263e4f;
$light-grey: #adadad;
$dark-background: #383838;
$shadow1:rgba($primary, 0.10) 0px 7px 29px 0px;
$shadow2:0 4px 30px rgba(0, 34, 64, 0.066);
$gradient1: linear-gradient(to top, #f7fafc 0%, #eef1f5 100%);
$yellow:#ffbb44;
$green:#7ace4c;
$red:#f33155;
$blue:#41b3f9;
$blue-01:#e7f6ff;
$green-01:#e2f6d8;
$yellow-01:#a3834e;
$primary-01: #ffede6;


body {
  overflow-x: hidden;
  width: 100% !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar {
    display: block;
  }
  &::-webkit-scrollbar-track {
      background: transparent;
  }
      
  &::-webkit-scrollbar-thumb {
      background-color: $primary;
      border-radius: 200px;
      border-right: none;
      border-left: none;
  }
  
  &::-webkit-scrollbar-track-piece:end {
      background: transparent;
  }
  
  &::-webkit-scrollbar-track-piece:start {
      background: transparent;
  }

}
* {
box-sizing: border-box;
}
a{
  color: $primary;

  &:hover{
    color: #adadad;
  }
}
label{
  white-space: break-spaces;
}
.radius-1{
  border-radius: 5px;
}
.radius-2{
  border-radius: 10px;
}
.radius-3{
  border-radius: 15px;
}
.radius-4{
  border-radius: 20px;
}
.border{
  border-color: #d0d0d0;
  border: solid 1px;
}
.border-bottom-1{
  border: solid 1px;
  border-bottom: 1px;
}
.radius-circle{
  border-radius: 100%;
}
.border-none{
  border-style: none !important;
}
.opacity-0{
opacity: 0;
}
.opacity-01{
opacity: .1;
}
.opacity-02{
opacity: .2;
}
.opacity-03{
opacity: .3;
}
.opacity-04{
opacity: .4;
}
.opacity-05{
opacity: .5;
}
.opacity-07{
opacity: .7;
}
.opacity-08{
opacity: .8;
}
.opacity-09{
opacity: .9;
}
.fs-40{
  font-size: 40px;
}
.fs-35{
font-size: 35px;
}
.fs-30{
font-size: 30px;
}
.fs-25{
font-size: 25px;
}
.fs-20{
font-size: 20px;
}
.fs-18{
font-size: 18px;
}
.fs-15{
font-size: 15px;
}
.fs-12{
font-size: 12px;
}
.fs-10{
font-size: 10px;
}
.relative{
position: relative;
}
.absolute{
position: absolute;
}
.fixed{
position: fixed;
}
.top-0{
top: 0;
}
.right-0{
right: 0;
}
.bottom-0{
bottom: 0;
}
.left-10{
left: 10px;
}
.top-10{
top: 10px;
}
.right-10{
right: 10px;
}
.bottom-10{
bottom: 10px;
}
.left-15{
left: 15px;
}
.top-15{
top: 15px;
}
.right-15{
right: 15px;
}
.bottom-15{
bottom: 15px;
}
.left-20{
left: 20px;
}
.top-20{
top: 20px;
}
.right-20{
right: 20px;
}
.bottom-20{
bottom: 20px;
}
.left-20{
left: 20px;
}
.left-40{
left: 40px;
}
.top-40{
top: 40px;
}
.right-40{
right: 40px;
}
.bottom-40{
bottom: 40px;
}
.left-40{
left: 40px;
}
.hide{
display: none !important;
}
.block{
display: block !important;
}
.z-index-top{
  z-index:9999;
}
.cursor-pointer{
cursor: pointer;
}
.cursor-grab{
cursor: grab;
}
.cursor-move{
cursor: move;
}
.disable-event{
  pointer-events: none;
}
.flex{
display: flex !important;
}
.flex-wrap{
  flex-wrap: wrap;
}
.column{
  flex-direction: column;

  label{
    margin-left: 0 !important;
  }
}
.gap-5{
gap:5px;
}
.gap-10{
gap:10px;
}
.gap-15{
gap:15px;
}
.gap-20{
gap:20px;
}
.gap-35{
gap:35px;
}
.my-10{
margin-top: 10px;
margin-bottom: 10px;
}
.my-15{
margin-top: 15px;
margin-bottom: 15px;
}
.my-20{
margin-top: 20px;
margin-bottom: 20px;
}
.mr-10{
margin-right: 10px;
}
.mr-20{
margin-right: 20px;
}
.mr-30{
margin-right: 30px;
}
.mr-50{
margin-right: 50px;
}
.mt-10{
margin-top: 10px;
}
.mt-20{
margin-top: 20px;
}
.mt-30{
margin-top: 30px;
}
.mt-50{
margin-top: 50px;
}
.mt-10vh{
margin-top: 10vh;
}
.ml-10{
  margin-left: 10px;
}
.m-0{
  margin: 0;
}
.mb-10{
margin-bottom: 10px;
}
.mb-20{
margin-bottom: 20px;
}
.mb-30{
margin-bottom: 30px;
}
.p-5{
padding: 5px;
}
.pt-5{
padding-top: 5px;
}
.pt-15{
padding-top: 15px;
}
.p-0{
  padding: 0px;
}
.pt-10{
padding-top: 10px;
}
.px-10{
padding-left: 10px;
padding-right: 10px;
}
.px-15{
padding-left: 15px;
padding-right: 15px;
}
.px-25{
padding-left: 25px;
padding-right: 25px;
}
.py-10{
padding-top: 10px;
padding-bottom: 10px;
}
.py-15{
padding-top: 15px;
padding-bottom: 15px;
}
.py-20{
padding-top: 20px;
padding-bottom: 20px;
}
.py-25{
padding-top: 25px;
padding-bottom: 25px;
}
.py-35{
padding-top: 35px;
padding-bottom: 35px;
}
.p-5{
  padding: 5px;
}
.p-10{
  padding: 10px;
}
.p-15{
padding: 15px;
}
.p-25{
padding: 25px;
}
.p-15{
padding: 15px;
}
.w-100{
width: 100%;
}
.w-300{
  width: 300px;
}
.vw-100{
width: 100vw;
}
.mw-sm{
max-width:300px;
}
.mw-md{
max-width:400px;
}
.mw-lg{
max-width:500px;
}
.mw-xl{
max-width:600px;
}
.mw-xxl{
max-width:700px;
}
.align-center{
align-items: center;
}
.justify-end{
justify-content: flex-end;
}
.space-between{
justify-content: space-between;
}
.space-around{
  justify-content: space-around;
}
.justify-center{
  justify-content: center;
}
.h-100{
  height: 100%;
}
.vh-100{
  height: 100vh;
}
.min-vh-100{
  min-height: 100vh;
}
.text-sm{
  font-size: 25px;
  font-weight: 500;
}
.color-primary{
  color: $primary;
} 
.color-transparent{
  color: transparent;
} 
.color-white{
  color: #fff;
}
.color-light-grey{
  color: #757575;
}
.color-blue{
  color: $blue;
}
.color-yellow{
  color: $yellow;
}
.color-green{
  color: $green;
}
.color-red{
  color: $red;
}
.color-black{
  color: rgb(15, 15, 15);
}
.color-secondary{
  color: $secondary;
}
.color-grey{
  color: $light-grey;
}
.hover-opacity-09:hover{
  opacity: 0.9;
}
.hover-primary:hover{
  color: $primary;
}
.hover-ml10:hover{
  transform: translateX(10px) ;
}
.hover-bg-green:hover{
  background-color: $green-01 !important;
}
.hover-bg-blue:hover{
  background-color: $blue-01 !important;
}
.hover-bg-yellow:hover{
  background-color: $yellow-01 !important;
}
.hover-bg-primary:hover{
  background-color: $primary-01 !important;
}
.fw-4{
  font-weight:400;
}
.fw-5{
  font-weight:500;
}
.fw-6{
  font-weight:600;
}
.bold{
  font-weight: bold;
}
.text-md{
  font-size: 50px;
  font-weight:700;
}
.text-xl{
  font-size: 70px;
  font-weight:700;
}
.text-center{
  text-align: center;
}
.text-right{
  text-align: right;
}
.bg-grey{
  background-color: #f3f3f3;
}
.bg-white{
  background-color: #fff;
}
.bg-blur{
backdrop-filter: blur(1px) brightness(0.97);
}
.bg-blur-md{
backdrop-filter: blur(3px) brightness(0.97);
}
.bg-blur-xl{
backdrop-filter: blur(5px) brightness(0.97);
}
.bg-primary{
background-color: $primary !important;
}
.bg-neutral{
background-color: $neutral !important;
}
.bg-secondary{
background-color: $secondary !important;
}
.bg-blue{
  background-color: $blue;
}
.bg-blue-01{
  background-color: $blue-01;
}
.bg-yellow{
  background-color: $yellow;
}
.bg-green{
  background-color: $green;
}
.bg-gradient1{
  background-image: $gradient1;
}
.bg-dark-01{
background-color: rgba(0, 0, 0, 0.026);
}
.ar-16-9{
  aspect-ratio: 16/9;
}
.ar-1{
  aspect-ratio: 1/1;
}
.x-scroll{
  overflow-x: scroll;
}
.overflow-hidden{
  overflow: hidden;
}
.white-space-nowrap{
  white-space: nowrap;
}
.word-break{
  word-break: break-all;
}
.hover-scale:hover{
  transform: scale(1.1);
}
.transition-01{
  transition: .1s;
}
.transition-02{
  transition: .2s;
}

// _______________ fine css caran design _______________ 

// _______________  generico override _______________ 
.ant-input-compact-item::placeholder {
  color: #676767 !important;
}
.ant-statistic-content-value-int{
  pointer-events: none;
  -webkit-touch-callout: none; 
    -webkit-user-select: none; 
     -khtml-user-select: none; 
       -moz-user-select: none;
        -ms-user-select: none; 
            user-select: none; 
}
.ant-modal-close {
  background: $primary !important;
  border: solid 2px #fff !important;
  color: #fff !important;
  border-radius: 200px ! important;
  height: 40px ! important;
  width: 40px ! important;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-divider{
  margin: 10px 0px;
}
.ant-card-body {
  padding: 15px !important;
}
.ant-card-bordered{
  overflow: hidden;
}
.notice-button{
  background-color: #7ace4c !important;
  color: #fff !important;
}
// _______________  generico override _______________ 

// _______________  home  _______________ 

.clouds{
  overflow-x: hidden;
  overflow-x: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;

  &.minimize{
    .cloud{
      width: 500px;
      transition-timing-function: ease-in-out;
    }
    .one{
      transform: translate(-100px,-150px);
    }
    .two{
      transform: translate(-100px,-100px);
    }
    .three{
      transform: translate(-100px,-100px);
    }
    .four{
      transform: translate(75px,-165px);
    }
  }
  .cloud{
    position: absolute;
    width: 700px;
    transition: .2s;
    z-index: 0;
  }
  .one{
    top: 250px;
    left: 0;
    opacity: .9;
  }
  .two{
    left: -250px;
    opacity: .6;
  }
  .three{
    right: -250px;
    opacity: .9;
  }
  .four{
    top: 250px;
    right: 0;
    opacity: .6;
  }
}

.logo{
  width: 250px;
}
.brand-area{
  z-index: 1;
  align-items: center;

  svg{
    width: 80px;
    .icon{
      fill: $primary;
      height: 200px !important;
    }

  }

  img{
    width: 100px;
  }
  .cemetery-name{
    font-size: 40px;
    width: 200px;
    color: $primary;
    line-height: 35px;
    font-weight: 600;
    font-family: "Varela Round", sans-serif;
  }
}
.search-box{
  margin-top: 100px;
  transition:.2s;
  &.minimize{
    margin-top: 0;
    transform: scale(.9);
  }
  .search-bar{
    width: 100%;
    max-width: 500px;
    .ant-input-clear-icon{
      font-size: 18px;
    }
    .cemetery-links{
      display: flex;
      gap:5px;
      flex-wrap: wrap;
      a{
        flex-basis: 150px;
        flex-grow: 1;
      }
    }
  }
}
.search-results{
  opacity: 0;

  .results-table{
    width: 100%;
    max-width: 1600px;
  }

  .ant-table-row{
    cursor: pointer;
  }
}
.ant-table-content{
  overflow-x: scroll;
}


// _______________  home  _______________ 

// _______________  scheda deceduto  _______________ 

.deceased-modal-wrapper {
    max-width: unset;
    width: 100% !important;
    height: 100% !important;
    top: 0;
    padding-bottom: unset;
    z-index: 980 !important;

    .ant-modal-body{
      height: 100%;
      background-size: cover !important;
      background-position: center !important;
      background-repeat: no-repeat !important;
    }
    .ant-modal-content{
      padding: 0px;
      height: 100%;
      background-color: #fff;
    }
    .deceased-modal-body{
      height: 100% !important;
      background-size: cover;
      overflow-y: auto;
      overflow-x: hidden !important;

      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }
      &::-webkit-scrollbar {
        display: block;
      }
      &::-webkit-scrollbar-track {
          background: transparent;
      }
          
      &::-webkit-scrollbar-thumb {
          background-color: $light-grey;
          border-radius: 200px;
          border-right: none;
          border-left: none;
      }
      
      &::-webkit-scrollbar-track-piece:end {
          background: transparent;
      }
      
      &::-webkit-scrollbar-track-piece:start {
          background: transparent;
      }

      
    }
}
.deceased-cover{
  height: 350px;
  background-size: cover !important;
  background-position: top !important;
}
.deceased-epitaph{
  font-family: 'Tangerine', serif;
  font-size: 60px;
}
.deceased-name{
  font-family: "Times New Roman", setif;
  font-size: 50px;
  background-color: #666666b1;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  word-break: break-word;
  color: transparent;
  line-height: 55px;
  text-shadow: rgb(255 255 255 / 50%) 1px 3px 2px;
  margin-top: 35px;
  overflow: hidden;
}
.deceased-image-wrapper{
  position: relative;
  .deceased-image{
    aspect-ratio: 1;
    width: 200px;
    border-radius: 200px;
    background-color: #fff;
    overflow: hidden;
    border: 2px solid #f4f4f4;
    justify-content: center;
    box-shadow: 0px 5px 12px #adadad69;
    margin-top: -100px;
  
    img{
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }
  .gift-wrapper{
    background-color: #fff;
    position: absolute;
    bottom: -30px;
    right: -60px;
    border-radius: 30px;
    padding: 5px 20px;
    box-shadow: 2px 5px 7px 1px #00000008;

    &::before{
      content: "";
      background-color: #fff;
      width: 70px;
      height: 70px;
      top: -20px;
      border-radius: 200px;
      position: absolute;
    }
    .deceased-gifts{
      z-index: 999;
    }
    .gift-icon{
      color: rgb(218, 181, 0);
    }
  }
}
.deceased-position{
  padding: 5px 10px;
  border: solid;
  border-radius: 5px;
  border-width: 1px;
  border-color: #adadad;
  margin: 5px;
}
#commentsWrapper, .comment-form-wrapper{
  max-width: 600px;
}
#commentsWrapper{
  margin-top: 10px;
  padding: 10px;
  padding-bottom: 30px;
  max-height: 400px;
  overflow: auto;
  -webkit-mask-image: linear-gradient(to top, transparent 5%, black 15%);
  mask-image: linear-gradient(to top, transparent 5%, black 15%);

  .infinite-scroll-component {
    overflow: unset !important;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar {
    display: block;
  }
  &::-webkit-scrollbar-track {
      background: transparent;
  }
      
  &::-webkit-scrollbar-thumb {
      background-color: $light-grey;
      border-radius: 200px;
      border-right: none;
      border-left: none;
  }
  
  &::-webkit-scrollbar-track-piece:end {
      background: transparent;
  }
  
  &::-webkit-scrollbar-track-piece:start {
      background: transparent;
  }

  .comment {
    background: #fff;
    padding: 15px;
    margin: 0px 0px 10px 0px;
    border-radius: 10px;
    box-shadow: 0px 8px 10px #0000000d;
  }  
}
.leaflet-control-attribution .leaflet-control {
  display: none !important;
}
.comment-form-wrapper{
  padding: 10px;
  margin: 10px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 20px 10px 20px;
  box-shadow: 0px 8px 10px #0000000d;
}
.tomb-picture{
  max-width: 500px;
  border-radius: 20px;
}
.deceased-map{
  height: 300px;
  margin: 20px 0px 20px 0px;
  max-width: 600px;
}
.footer{
  margin-top: auto;
  z-index: 1;
  .logo{
    width: 150px;
  }
  .cemetery-logo{
    max-width: 50px;
    mix-blend-mode: lighten;
  }
  .cemetery-name{
    color: #fff;
    font-size: 25px;
    line-height: 24px;
    font-weight: 500;
  }
  a{
    color: #fff;
    font-size: 18px;
  }
}
.deceased-actions{
  a{
    flex-grow: 1;
    flex-basis: 90;
  }
}



.single-page-deceased {
  .deceased-modal-body{
    height: 100%;
    background-size: cover;
  }
}

.leaflet-container .leaflet-control-attribution {
  display: none !important;
}
// _______________  scheda deceduto  _______________ 

// _______________  scheda prodotti  _______________ 

.product-card{
  .ant-card-cover{
    aspect-ratio: 1;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      height: 100%;
      object-fit: cover;
    }
  }
  .add-to-cart{
    width: 90%;
  }
}
.cart-drawer{
  height: 100vh;
  position:fixed;
  width: 100%;
  right: 0;
  top: 0;
  max-width: 400px;
  background-color: #fff;
  box-shadow: -3px 0px 15px 1px #00000014;
  z-index: 9999;
  transition: .2s;
  right:-450px;
}
.cart-product-list{
  overflow-y: auto;
  max-height:80vh;
}
.cart-product{
  padding: 0px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .cart-product-image{
    aspect-ratio: 1;
    width: 50px;
  }
  .cart-product-title{
    font-size: 18px;
  }
  .cart-product-price{
    font-size: 18px;
    font-weight: bold;
    color: $primary;
  }
}
.cart-drawer[open]{
  right:0px
}
.cart-overlay{
  height: 100vh;
  width: 100vw;
  background-color: #0000002f;
  backdrop-filter: blur(2px);
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  display: none;
  z-index: 9999;
  transition: .2s;
  overflow: hidden;
}
.cart-overlay[open]{
  display: block;
  opacity: 1;
}
.close-cart{
  margin: 10px;
}
// _______________  scheda prodotti  _______________ 

//animazioni
.fadeIn{
  animation: fadeIn .2s forwards;
}
@keyframes fadeIn {
  0%{opacity: 0;}
  100%{opacity: 1;}
}
//responsive
@media all and (max-width:950px){
  .cloud{
    width: 450px !important;
  }
  .search-box{
    padding: 10px;
  }
}
@media all and (max-width:450px){
  .search-box{
    margin-top: 50px;
  }
  .deceased-cover{
    height: 200px !important;
  }
  .deceased-epitaph{
    font-size: 40px;
    margin-top: -85px;
  }
  .services-drawer-body{
    margin: -10px;
  }
  .deceased-name{
    font-size: 30px;
    line-height: 30px;
  }
}

// candela

.candle-wrapper{
  position: absolute;
  top: -95px;
  z-index: 10;
  transform: scale(0.25);
  left: -70px;

  *, *:before, *:after {
    margin: 0;
    padding: 0;
    word-break: break-all;
    box-sizing: border-box;
    scroll-behavior: smooth;
    left: 0;
  }
  
  .center {
    width: 1170px;
    margin: 20px auto 0;
  }
  
  .holder {
    width: 150px;
    height: 400px;
    position: relative;
  }
  
  .holder *, .holder *:before, .holder *:after {
    position: absolute;
    content: "";
  }
  
  .candle {
    position: relative;
    bottom: 0;
    width: 150px;
    height: 300px;
    border-radius: 150px / 40px;
    box-shadow: inset 20px -30px 50px 0 rgba(0, 0, 0, 0.4), inset -20px 0 50px 0 rgba(0, 0, 0, 0.4);
    background: #190f02;
    background: -moz-linear-gradient(#e48825, #e78e0e, #833c03, #4c1a03 50%, #1c0900);
    background: -webkit-linear-gradient(#e48825, #e78e0e, #833c03, #4c1a03 50%, #1c0900);
    background: -o-linear-gradient(#e48825, #e78e0e, #833c03, #4c1a03 50%, #1c0900);
    background: -ms-linear-gradient(#e48825, #e78e0e, #833c03, #4c1a03 50%, #1c0900);
    background: linear-gradient(#e48825, #e78e0e, #833c03, #4c1a03 50%, #1c0900);
  }
  
  .candle:before {
    width: 100%;
    height: 40px;
    border-radius: 50%;
    border: 2px solid #d47401;
    background: #b86409;
    background: -moz-radial-gradient(#eaa121, #8e4901 45%, #b86409 80%);
    background: -webkit-radial-gradient(#eaa121, #8e4901 45%, #b86409 80%);
    background: -o-radial-gradient(#eaa121, #8e4901 45%, #b86409 80%);
    background: -ms-radial-gradient(#eaa121, #8e4901 45%, #b86409 80%);
    background: radial-gradient(#ffef80, #b86409 60%);
    background: radial-gradient(#eaa121, #8e4901 45%, #b86409 80%);
  }
  
  .candle:after {
    width: 34px;
    height: 10px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
    top: 14px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
    background: -moz-radial-gradient(rgba(0, 0, 0, 0.6), transparent 45%);
    background: -webkit-radial-gradient(rgba(0, 0, 0, 0.6), transparent 45%);
    background: -o-radial-gradient(rgba(0, 0, 0, 0.6), transparent 45%);
    background: -ms-radial-gradient(rgba(0, 0, 0, 0.6), transparent 45%);
    background: radial-gradient(rgba(0, 0, 0, 0.6), transparent 45%);
  }
  
  .thread {  
    width: 6px;
    height: 36px;
    top: -17px;
    left: 50%;
    z-index: 1;
    border-radius: 40% 40% 0 0;
    transform: translateX(-50%);
    background: #121212;
    background: -moz-linear-gradient(#d6994a, #4b232c, #121212, black, #e8bb31 90%);
    background: -webkit-linear-gradient(#d6994a, #4b232c, #121212, black, #e8bb31 90%);
    background: -o-linear-gradient(#d6994a, #4b232c, #121212, black, #e8bb31 90%);
    background: -ms-linear-gradient(#d6994a, #4b232c, #121212, black, #e8bb31 90%);
    background: linear-gradient(#d6994a, #4b232c, #121212, black, #e8bb31 90%);
  }
  
  .flame {
    width: 24px;
    height: 120px;
    left: 50%;
    transform-origin: 50% 100%;
    transform: translateX(-50%);
    bottom: 100%;
    border-radius: 50% 50% 20% 20%;
    background: rgba(255, 255, 255, 1);
    background: -moz-linear-gradient(white 80%, transparent);
    background: -webkit-linear-gradient(white 80%, transparent);
    background: -o-linear-gradient(white 80%, transparent);
    background: -ms-linear-gradient(white 80%, transparent);
    background: linear-gradient(white 80%, transparent);
    animation: moveFlame 6s linear infinite, enlargeFlame 5s linear infinite;
  }
  
  .flame:before {
    width: 100%;
    height: 100%;
    border-radius: 50% 50% 20% 20%;
    box-shadow: 0 0 15px 0 rgba(247, 93, 0, .4), 0 -6px 4px 0 rgba(247, 128, 0, .7);
  }
  
  @keyframes moveFlame {
    0%, 100% {
      transform: translateX(-50%) rotate(-2deg);
    }
    50% {
      transform: translateX(-50%) rotate(2deg);
    }
  }
  
  @keyframes enlargeFlame {
    0%, 100% {
      height: 120px;
    }
    50% {
      height: 140px;
    }
  }
  
  .glow {
    width: 26px;
    height: 60px;
    border-radius: 50% 50% 35% 35%;
    left: 50%;
    top: -48px;
    transform: translateX(-50%);
    background: rgba(0, 133, 255, .7);
    box-shadow: 0 -40px 30px 0 #dc8a0c, 0 40px 50px 0 #dc8a0c, inset 3px 0 2px 0 rgba(0, 133, 255, .6), inset -3px 0 2px 0 rgba(0, 133, 255, .6);
  }
  
  .glow:before {
    width: 70%;
    height: 60%;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.35);
  }
  
  .blinking-blow {
    width: 100px;
    height: 180px;
    left: 50%;
    top: -55%;
    transform: translateX(-50%);
    border-radius: 50%;
    background: #ff6000;
    -webkit-filter: blur(50px);
    -moz-filter: blur(60px);
    -o-filter: blur(60px);
    -ms-filter: blur(60px);
    filter: blur(60px);
    animation: blinkIt .1s infinite;
  }
  .blinking-blow.shadow {
    position: absolute;
    top: unset;
    left: 34px;
    background: #292929;
    bottom: -81px;
    transform: rotateX(105deg);
  }
  @keyframes blinkIt {
    50% { opacity: .8;}
  }
  


}
